import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ShadowSwatches } from '../docz-components/Swatches';
import { shadows } from './shadows';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "shadows"
    }}>{`Shadows`}</h1>
    <p>{`The shadow key contains a scale of shadows to be used with the `}<inlineCode parentName="p">{`boxShadow`}</inlineCode>{` and `}<inlineCode parentName="p">{`textShadow`}</inlineCode>{` props`}</p>
    <ShadowSwatches shadows={shadows} mdxType="ShadowSwatches" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      